import React from 'react'
import { Link, graphql } from 'gatsby'
import PrismicDOM from 'prismic-dom'
import Container from '../components/container'
import logo from '../images/coronacamp-small.svg'
import _ from 'lodash'

export default ({ data: { prismic: { allCategorys: { edges: categories }, allResources: { edges: resources } } } }) => {
  const categoryMap = _.groupBy(categories, ({ node: { _meta: { uid } } }) => { return uid })
  Object.entries(categoryMap).forEach(([category, categoryList]) => {
    const { node: { title, description } } = categoryList.pop()
    categoryMap[category] = {title: title, description: description}
  })
  const byCategory = _.groupBy(resources, ({ node: { category: { _meta: { uid } } } }) => { return uid })
  return (
    <Container>
      <div className="mt-20 mb-16 text-center">
        <Link to="/">
          <img className="inline-block" width={261} src={logo} alt="Corona Camp" />
        </Link>
      </div>
      {Object.keys(byCategory).map(category => (
        <div key={category}>
          <p className="mt-32 text-center text-sm font-semibold text-yellow-800">{categoryMap[category].title}</p>
          {byCategory[category].map(({ node: { title, source, link, category: { title: categoryTitle, _meta: { uid: categoryUid } }, _meta: { id } } }) => {
            const titleText = PrismicDOM.RichText.asText(title)
            return (
              <div key={id}>
                <h2 className="mb-1">
                  {link ? (<a href={link.url}>{titleText}</a>) : titleText}
                </h2>
                <p className="text-center text-xs">{source}</p>
              </div>
            )
          })}
        </div>
      ))}
    </Container>
  )
}

export const query = graphql`
  query ResourcesQuery {
    prismic {
      allCategorys {
        edges {
          node {
            title
            description
            _meta {
              uid
            }
          }
        }
      }
      allResources {
        edges {
          node {
            title
            source
            link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            category {
              ... on PRISMIC_Category {
                _meta {
                  uid
                }
              }
            }
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
